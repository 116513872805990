<template>
  <div>
    <h3>Pedidos dos Clientes</h3>
    <div class="row" id="search">
      <div class="col-sm-4">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"
              >Choose Status:</span
            >
          </div>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="status"
            @change="filterRequests()"
          >
            <option value="last_updated">Últimas Atualizações</option>
            <option value="pending">Pendentes</option>
            <option value="offer">Ofertas</option>
            <option value="counter-offer">Contra Oferta</option>
            <option value="canceled">Canceladas</option>
            <option value="accepted">Aceites</option>
            <option value="sent">Enviados</option>
            <option value="all">Todos os pedidos</option>
          </select>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">SKU:</span>
          </div>
          <input
            id="sku"
            type="text"
            class="form-control"
            v-model="sku"
            @change="filterRequests()"
          />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"
              >Email Cliente:</span
            >
          </div>
          <input
            id="sku"
            type="text"
            class="form-control"
            v-model="email"
            @change="filterRequests()"
          />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"
              >Nome Produto:</span
            >
          </div>
          <input
            id="sku"
            type="text"
            class="form-control"
            v-model="productName"
            @change="filterRequests()"
          />
        </div>
      </div>
    </div>
    <b-table :items="requests" :fields="fields" class="table">
      <template #cell(date)="data">
        <span v-if="data.item.date == null">-</span>
        <span v-else> {{ splitDate(data.item.date) }} </span>
        <br />
        <span v-if="data.item.urgent == true" class="urgent"
          ><b>⚠️ URGENT</b></span
        >
      </template>
      <template #cell(sku)="data">
        <span
          v-if="
            data.item.product_link != '' && data.item.product_link != undefined
          "
          ><a :href="data.item.product_link" target="_blank">{{
            data.item.sku
          }}</a></span
        >
        <span v-else
          ><a
            :href="'https://www.amazon.ae/s?k=' + data.item.sku"
            target="_blank"
            >{{ data.item.sku }}</a
          ></span
        >
        <span v-if="data.item.product_id_backoffice != null">
          <hr />
          <!-- {{ data.item.our_sku[1] }} -->
          <span v-for="sku in data.item.our_sku" :key="sku"
            ><a @click="openProduct(data)"> {{ sku }}</a> <br /> </span
        ></span>
      </template>
      <template #cell(status)="data">
        <span v-if="data.item.status == 'pending'" class="pending">
          Pending</span
        ><span v-else-if="data.item.status == 'offer'" class="offer">
          Offer</span
        >
        <span v-else-if="data.item.status == 'canceled'"
          ><span class="canceled">Canceled</span>
          <p class="cancelStatus">{{ data.item.cancel_status }}</p></span
        >
        <span v-else-if="data.item.status == 'accepted'"
          ><span class="accepted">Accept</span>
          <p class="cancelStatus" v-if="data.item.userAccept">
            Aceite: <b>{{ data.item.userAccept }}</b>
          </p></span
        >
        <span
          v-else-if="data.item.status == 'counter-offer'"
          class="counter-offer"
        >
          Counter Offer</span
        >
        <span v-else-if="data.item.status == 'sent'"
          ><span class="sent">Sent</span>
          <p class="cancelStatus" v-if="data.item.userAccept">
            Aceite: <b>{{ data.item.userAccept }}</b>
          </p></span
        >
      </template>
      <template #cell(name)="data">
        <span v-if="data.item.product_id_backoffice != null">
          {{ data.item.name }}
          <hr />
          {{ data.item.our_name }}
        </span>
        <span v-else>{{ data.item.name }}</span>
      </template>
      <template #cell(price_offer)="data">
        <p><b>Sale: </b>{{ data.item.price_sale }}</p>
        <p>
          <b>Offer: </b><span v-if="data.item.price_offer == null">-</span>
          <span v-else>{{ data.item.price_offer }}</span>
        </p>
        <p>
          <b>Counter Offer: </b
          ><span v-if="data.item.price_counteroffer == null">-</span>
          <span v-else>{{ data.item.price_counteroffer }}</span>
        </p>
      </template>
      <template #cell(price)="data">
        <span v-if="data.item.price == null">-</span>
        <span v-else>{{ data.item.price }}</span>
      </template>
      <template #cell(comments)="data">
        <div v-if="checkHowManyMsgs(data) != 0">
          <button class="btn btn-info" id="notification" @click="openMsg(data)">
            💬
            <span class="badge">{{ checkHowManyMsgs(data) }}</span>
          </button>
        </div>
        <div v-else>
          <button class="btn btn-info" @click="openMsg(data)">💬</button>
        </div>
      </template>
      <template #cell(cancel)="data">
        <span
          v-if="
            data.item.status == 'pending' || data.item.status == 'counter-offer'
          "
        >
          <span v-if="data.item.product_id_backoffice != null"
            ><a class="events" v-b-modal.modal_offer @click="openOffer(data)"
              >✅</a
            ></span
          >
        </span>
        <span v-else-if="data.item.status == 'accepted'">
          <button
            class="btn btn-info btn-sm"
            @click="sentRequest(data.item.id)"
          >
            Enviado
          </button>
          <span v-if="data.item.qty_sent == null">
            <button class="btn btn-warning btn-sm" @click="backInStatus(data)">
              Voltar
            </button>
          </span>
        </span>
        <a class="events" v-b-modal.modal-cancel @click="dataAssociate(data)"
          >❌</a
        >
        <br />
        <button class="btn btn-primary btn-sm" @click="openModalEdit(data)">
          Edit
        </button>
      </template>
      <template #cell(img)="data">
        <span>
          <a :href="data.item.img" type="file" target="_blank"
            ><img
              :src="data.item.img"
              alt="Product Image"
              style="width: 100px; height: 100px"
            />
          </a>
        </span>
      </template>
      <template #cell(our_img)="data">
        <span v-if="data.item.our_img != null">
          <a :href="data.item.our_img" type="file" target="_blank"
            ><img
              :src="data.item.our_img"
              alt="Product Image"
              style="width: 100px; height: 100px"
            />
          </a>
        </span>
        <span v-else>- </span>
      </template>
      <template #cell(associate)="data">
        <span v-if="data.item.product_id_backoffice == null"
          ><a v-b-modal.modal_associate_product @click="dataAssociate(data)"
            >➕</a
          ></span
        >
        <span
          v-else
          v-b-modal.modal_associate_product
          @click="dataAssociateUpdate(data)"
          >✅</span
        >
      </template>
      <template #cell(qty)="data">
        <p>
          <span> <b>Pedida: </b> {{ data.item.qty }} </span>
        </p>
        <p>
          <b>Conf: </b>
          <span v-if="data.item.qty_accepted == null">-</span>
          <span v-else>{{ data.item.qty_accepted }}</span>
        </p>
        <p>
          <b>Enviada: </b><span v-if="data.item.qty_sent == null">-</span>
          <span v-else>{{ data.item.qty_sent }}</span>
        </p>
      </template>
    </b-table>
    <b-modal
      ref="modal_associate_product"
      id="modal_associate_product"
      title="Associar Produto"
      hide-footer
    >
      <div class="form-group">
        <label for="sku">SKU</label>
        <input
          type="text"
          class="form-control"
          id="sku"
          v-model="skuSearch"
          @change="getProductInfo()"
        />
      </div>
      <span v-if="product.length > 0">
        <img
          :src="product[0].img"
          alt=""
          style="width: 300px; height: 300px; margin-left: 15%"
        />
        <h6>{{ product[0].name }}</h6>
        <p><b>SKU:</b> {{ product[0].sku }}</p>
        <p><b>EAN:</b> {{ product[0].ean }}</p>
        <p><b>Stock:</b> {{ product[0].stock }}</p>
      </span>
      <div>
        <button
          class="btn btn-primary"
          @click="associateProduct()"
          :disabled="product.length == 0"
        >
          Associar
        </button>
      </div>
    </b-modal>
    <b-modal
      ref="modal_offer"
      id="modal_offer"
      title="Aceitar Pedido"
      hide-footer
    >
      {{ savePrice }}
      <p><b>Nome: </b> {{ nameOffer }}</p>
      <p><b>SKU's: </b>{{ skuOffer }}</p>
      <p><b>Stock: </b>{{ stockOffer }}</p>
      <p><b>Preço Custo: </b>{{ pCustoOffer }}</p>
      <p><b>Quantidade Pedida: </b>{{ qtyOffer }}</p>
      <p v-if="statusOffer == 'counter-offer'">
        <b>Antiga Proposta: </b>{{ oldPriceOffer }}
      </p>
      <p v-if="statusOffer == 'counter-offer'">
        <b>Contra Oferta: </b>{{ counterOffer }}
      </p>
      <form v-on:submit.prevent="makeOffer()">
        <div class="form-group">
          <label for="price_offer"><b>Quantidade Disponivel:</b></label>
          <input
            type="number"
            class="form-control"
            id="price_offer"
            v-model="qtyAccepted"
          />
        </div>
        <div class="form-group">
          <label for="price_offer"><b>Preço Oferta:</b></label>
          <input
            type="number"
            class="form-control"
            id="price_offer"
            v-model="price_offer"
          />
        </div>
        <div class="form-group" v-if="savePrice!= true">
          <label for="save_price"><b>Guardar Preço?</b></label><br>
          <input type="checkbox" v-model="checkPrice" id="flexCheckDefault"> 
          <label for="flexCheckDefault">Sim</label>

        </div>
        <div style="text-align: center">
          <span v-if="statusOffer == 'counter-offer'">
            <button
              class="btn btn-success"
              type="button"
              @click="acceptOffer()"
              style="margin: 3px"
            >
              Aceitar proposta
            </button></span
          >
          <button class="btn btn-primary" style="margin: 3px">
            Fazer proposta
          </button>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-msg" ref="modal-msg" title="Comments" hide-footer>
      <span v-for="msg of msgs" :key="msg">
        <span v-if="msg.user == userEmail || msg.user == username">
          <div class="card" id="msg" style="border-color: blue">
            <div class="card-body" style="text-align: right">
              <b>{{ msg.user }}:</b> {{ msg.comment }}
            </div>
          </div></span
        >
        <span v-else>
          <div class="card" id="msg">
            <div class="card-body" style="text-align: left">
              <b>{{ msg.user }}:</b> {{ msg.comment }}
            </div>
          </div></span
        ></span
      >
      <hr />
      <span>New Message:</span>
      <textarea
        class="form-control"
        aria-label="With textarea"
        v-model="newMsg"
      ></textarea>
      <button class="btn btn-info float-right" @click="sendComment()">
        Send
      </button>
    </b-modal>
    <b-modal id="modal-edit" ref="modal-edit" title="Edit Request" hide-footer>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="sku">Prod. SKU</span>
        </div>
        <input
          type="text"
          class="form-control"
          aria-describedby="sku"
          v-model="editSku"
          required
          disabled
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Name</span>
        </div>
        <input
          type="text"
          class="form-control"
          aria-describedby="sku"
          v-model="editName"
          required
          disabled
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Product Link</span>
        </div>
        <input
          type="text"
          class="form-control"
          aria-describedby="sku"
          v-model="editLink"
          disabled
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Price Sale</span>
        </div>
        <input
          type="number"
          class="form-control"
          aria-describedby="sku"
          v-model="editPriceSale"
          required
          disabled
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Quantidade Pedida</span>
        </div>
        <input
          type="number"
          class="form-control"
          aria-describedby="sku"
          v-model="editQty"
          required
          disabled
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Quantidade Pedida</span>
        </div>
        <input
          type="number"
          class="form-control"
          aria-describedby="sku"
          v-model="editQtyAccepted"
          required
        />
      </div>
      <div v-if="editStatus == 'offer'" class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Offer</span>
        </div>
        <input
          type="number"
          class="form-control"
          aria-describedby="sku"
          v-model="editOffer"
          required
        />
      </div>
      <div v-if="editStatus == 'counter-offer'" class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Counter Offer</span>
        </div>
        <input
          type="number"
          class="form-control"
          aria-describedby="sku"
          v-model="editCounterOffer"
          required
          disabled
        />
      </div>
      <div v-if="editStatus == 'accepted'" class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Final Price</span>
        </div>
        <input
          type="number"
          class="form-control"
          aria-describedby="sku"
          v-model="editFinalPrice"
          required
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Type</span>
        </div>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          v-model="editType"
          disabled
        >
          <option value="normal">Normal</option>
          <option value="fba">FBA</option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Image Link</span>
        </div>
        <input
          type="text"
          class="form-control"
          aria-describedby="sku"
          v-model="editImage"
          disabled
        />
      </div>
      <img
        :src="editImage"
        alt=""
        style="width: 300px; height: 300px; margin-left: 15%"
      />
      <div>
        <button class="btn btn-primary" @click="editRequest()">Edit</button>
      </div>
    </b-modal>
    <b-modal
      id="modal-cancel"
      ref="modal-cancel"
      title="Cancel Request"
      hide-footer
    >
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="sku">Reason</span>
        </div>
        <select class="form-select" v-model="cancelStatus">
          <option selected value="Not available">Not available</option>
          <option value="Impossible to ship">Impossible to ship</option>
          <option value="Descontinued - Remove from the marketplace">
            Not available (Descontinued - Remove from the marketplace)
          </option>
          <option values="Cancelled by the customer">
            Cancelled by the customer
          </option>
          <option values="Canceled - Due to exceeding the logical period to send to the customer">
            Canceled - Due to exceeding the logical period to send to the customer
          </option>
        </select>
      </div>
      <div>
        <button class="btn btn-danger" @click="cancelRequest()">
          Cancel Request
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      requests: [],
      fields: [
        {
          key: "id",
          label: "ID",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "date",
          label: "Date",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "img",
          label: "Image",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "our_img",
          label: "Our Img",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "sku",
          label: "SKU",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "name",
          label: "Prod. Name",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "qty",
          label: "Quantity",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        // {
        //   key: "price_sale",
        //   label: "Price Sale",
        //   tdClass: "align-middle",
        //   thStyle:
        //     "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        // },
        {
          key: "price_offer",
          label: "Prices",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "price",
          label: "Final Price",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "type",
          label: "Type",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147); vertical-align: middle",
        },
        {
          key: "comments",
          label: "Comments",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147); vertical-align: middle",
        },
        // {
        //   key: "price_counteroffer",
        //   label: "Price Counter Offer",
        //   tdClass: "align-middle",
        //   thStyle:
        //     "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        // },
        {
          key: "associate",
          label: "Associate",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147); vertical-align: middle",
        },
        {
          key: "cancel",
          label: "Events",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
      ],
      skuSearch: "",
      product: [],
      skuClient: "",
      idRequest: "",
      emailClient: "",
      price_offer: "",
      skuOffer: "",
      nameOffer: "",
      stockOffer: "",
      pCustoOffer: "",
      statusOffer: "",
      counterOffer: "",
      oldPriceOffer: "",
      qtyOffer: "",
      qtyAccepted: "",
      status: "last_updated",
      sku: "",
      email: "",
      msgs: [],
      userEmail: "",
      newMsg: "",
      editSku: "",
      editName: "",
      editImage: "",
      editLink: "",
      editPriceSale: 0,
      editType: "",
      editQty: 0,
      editId: 0,
      editCounterOffer: 0,
      editOffer: 0,
      editStatus: "",
      editFinalPrice: 0,
      editQtyAccepted: 0,
      username: "",
      cancelStatus: "Not available",
      actualStatus: "",
      productName: "",
      savePrice: "",
      checkPrice:false,
      id_backoffice: "",
    };
  },
  methods: {
    async openOffer(data) {
      console.log(data.item);
      this.skuOffer = data.item.our_sku;
      this.nameOffer = data.item.our_name;
      this.stockOffer = data.item.our_stock;
      this.pCustoOffer = data.item.p_custo;
      this.idRequest = data.item.id;
      this.statusOffer = data.item.status;
      this.counterOffer = data.item.price_counteroffer;
      this.oldPriceOffer = data.item.price_offer;
      this.qtyOffer = data.item.qty;
      this.qtyAccepted = data.item.qty;
      this.emailClient = data.item.email;
      this.actualStatus = data.item.status;
      this.savePrice = data.item.price_save;
      this.id_backoffice = data.item.id_backoffice;
      // this.$refs.modal_offer.show();
    },
    async getRequests() {
      try {
        await this.$store.dispatch("allLastUpdatedRequests");
        console.log(this.getAllRequestsClient);
        this.requests = this.getAllRequestsClient;
      } catch (error) {
        console.log(error);
      }
    },
    async getProductInfo() {
      try {
        await this.$store.dispatch("fetchProductsSKU", {
          sku: this.skuSearch,
        });
        console.log(this.getProduct);
        this.product = this.getProduct;
      } catch (error) {
        console.log(error);
      }
    },
    async getProductInfoById(id) {
      try {
        await this.$store.dispatch("fetchProductID", {
          id: id,
        });
        console.log(this.getProduct_ID.data.product);
        this.product = this.getProduct_ID.data.product;
        this.skuSearch = this.product[0].sku[0];
      } catch (error) {
        console.log(error);
      }
    },
    async dataAssociate(data) {
      this.idRequest = data.item.id;
      this.skuClient = data.item.sku;
      this.emailClient = data.item.email;
    },
    async dataAssociateUpdate(data) {
      this.idRequest = data.item.id;
      this.skuClient = data.item.our_sku;
      this.emailClient = data.item.email;
      this.idBackoffice = data.product_id_backoffice;
      await this.getProductInfoById(data.item.product_id_backoffice);
    },
    async associateProduct() {
      try {
        await this.$store.dispatch("associateProduct", {
          id: this.idRequest,
          id_backoffice: this.product[0].id_backoffice,
          sku_client: this.skuClient,
          email: this.emailClient,
        });
        // console.log(this.getProduct);
        this.product = [];
        this.skuSearch = "";
        this.$refs["modal_associate_product"].hide();
        await this.filterRequests();
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async makeOffer() {
      try {
        await this.$store.dispatch("updateClientOffer", {
          id: this.idRequest,
          status: "offer",
          price_offer: this.price_offer,
          qty_accepted: this.qtyAccepted,
          actual_status: this.actualStatus,
          save_price: this.checkPrice,
          id_backoffice:this.id_backoffice,
          client_email: this.emailClient,
        });
        this.$swal({
          title: "Sucesso!",
          text: "Oferta feita com sucesso!",
          icon: "success",
          button: "OK",
        });
        this.price_offer = "";
        this.checkPrice=false
        this.$refs["modal_offer"].hide();
        await this.filterRequests();
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async cancelRequest() {
      this.$swal({
        title: "Tem a certeza",
        text: "Não será capaz de reverter esta ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5a4ec7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, cancelar pedido!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("cancelRequestClient", {
              id: this.idRequest,
              cancelStatus: this.cancelStatus,
            });
            // this.getRequests();
            this.$swal(
              "Pedido Cancelado!",
              "Seu pedido foi cancelado.",
              "success"
            );
            await this.filterRequests();
            this.$refs["modal-cancel"].hide();
          } catch (error) {
            alert(error);
            console.log(error);
          }
        }
      });
    },
    async acceptOffer() {
      this.$swal({
        title: `O valor do produto será ${this.counterOffer}!`,
        text: "Não será capaz de reverter esta ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5a4ec7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, aceitar oferta!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("acceptRequest", {
              id: this.idRequest,
              status: "accepted",
              price_final: this.counterOffer,
              email: this.emailClient,
            });
            this.$swal({
              title: "Sucesso!",
              text: "Oferta aceite com sucesso!",
              icon: "success",
              button: "OK",
            });
            this.$refs["modal_offer"].hide();
            await this.filterRequests();
          } catch (error) {
            alert(error);
            console.log(error);
          }
        }
      });
    },
    async filterRequests() {
      try {
        await this.$store.dispatch("filterAllRequests", {
          status: this.status,
          sku: this.sku,
          email: this.email,
          name: this.productName,
        });
        this.requests = this.getAllRequestsClient;
      } catch (error) {
        console.log(error);
      }
    },
    splitDate(date) {
      let split = date.split("T");
      return split[0];
    },
    async getUser() {
      let user = localStorage.getItem("user");
      this.userEmail = JSON.parse(user).email;
      this.username = JSON.parse(user).username;
    },
    async sendComment() {
      try {
        await this.$store.dispatch("addCommentToRequest", {
          id: this.modalid,
          comment: this.newMsg,
          oldComments: this.msgs,
        });
        await this.getRequests();
        await this.getmsgs(this.modalid);
        this.newMsg = "";
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async getmsgs(id) {
      this.requests.filter((item) => {
        if (item.id == id) {
          this.msgs = JSON.parse(item.comments);
        }
      });
    },
    async openMsg(data) {
      this.modalid = data.item.id;
      this.msgs = JSON.parse(data.item.comments);
      this.$refs["modal-msg"].show();
    },
    checkHowManyMsgs(data) {
      let msgs = JSON.parse(data.item.comments);
      console.log(msgs);
      let totalNewMsgs = 0;
      console.log(msgs.length);
      for (let i = msgs.length - 1; i >= 0; i--) {
        console.log(i);
        if (msgs[i].user != this.userEmail && msgs[i].user != this.username) {
          totalNewMsgs++;
        } else {
          break;
        }
      }
      return totalNewMsgs;
    },
    async openModalEdit(data) {
      this.$refs["modal-edit"].show();
      this.editId = data.item.id;
      this.editName = data.item.name;
      this.editPriceSale = data.item.price_sale;
      this.editImage = data.item.img;
      this.editSku = data.item.sku;
      this.editQty = data.item.qty;
      this.editType = data.item.type;
      this.editLink = data.item.link;
      this.editCounterOffer = data.item.price_counteroffer;
      this.editOffer = data.item.price_offer;
      this.editStatus = data.item.status;
      this.editFinalPrice = data.item.price;
      this.editQtyAccepted = data.item.qty_accepted;
    },
    async editRequest() {
      try {
        await this.$store.dispatch("editRequestClient", {
          id: this.editId,
          img: this.editImage,
          name: this.editName,
          sku: this.editSku,
          price_sale: this.editPriceSale,
          product_link: this.editLink,
          type: this.editType,
          qty: this.editQty,
          counterOffer: this.editCounterOffer,
          priceOffer: this.editOffer,
          price: this.editFinalPrice,
          qty_accepted: this.editQtyAccepted,
        });
        this.$swal({
          title: "Success!",
          text: "Request successfully edited!",
          icon: "success",
          button: "Ok",
        });
        this.$refs["modal-edit"].hide();
        this.filterRequests();
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async backInStatus(data) {
      let newStatus = "pending";
      if (
        data.item.price_counteroffer != null &&
        data.item.price_counteroffer != 0
      ) {
        newStatus = "counter-offer";
      }
      this.$swal({
        title: "Are you sure?",
        text: `The request status will be changed to ${newStatus}!}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5a4ec7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("backinStatusRequestClient", {
              id: data.item.id,
              status: newStatus,
              email: data.item.email,
            });
            this.$swal({
              title: "Success!",
              text: "Request successfully sent!",
              icon: "success",
              button: "Ok",
            });
            this.filterRequests();
          } catch (error) {
            alert(error);
            console.log(error);
          }
        }
      });
    },
    // REMOVER DEPOIS
    async sentRequest(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5a4ec7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, send request!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("sentRequestClient", {
              id: id,
            });
            this.$swal({
              title: "Success!",
              text: "Request successfully sent!",
              icon: "success",
              button: "Ok",
            });
            this.filterRequests();
          } catch (error) {
            alert(error);
            console.log(error);
          }
        }
      });
    },
    async openProduct(data) {
      localStorage.setItem("product", JSON.stringify(data.item.our_sku[0]));
      window.open(`https://${window.location.host}/about`, "_blank");
    },
  },
  computed: {
    ...mapGetters(["getAllRequestsClient"]),
    ...mapGetters(["getProduct"]),
    ...mapGetters(["getProduct_ID"]),
  },
  created() {
    this.getRequests();
    this.getUser();
  },
};
</script>
<style scoped>
h3 {
  margin: 15px;
  text-align: center;
}
.table {
  text-align: center;
}
.pending {
  background-color: rgb(26, 140, 196);
  padding: 6px;
  border-radius: 8px;
  color: white;
}
.offer {
  background-color: #5a4ec7;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}

.events {
  margin-left: 10px;
  margin-right: 10px;
}
.counter-offer {
  background-color: #ffaf02;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
.canceled {
  background-color: #b5182a;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
.accepted {
  background-color: #538102;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
.sent {
  background-color: purple;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
#search {
  margin: 20px;
}
#msg {
  margin: 5px;
}
#notification {
  padding-right: 0px;
}
#notification .badge {
  color: red;
  top: -17px;
  right: -6px;
  border-radius: 50%;
  background: red;
  color: white;
}
a {
  cursor: pointer;
}
.cancelStatus {
  margin-top: 10px;
  text-decoration: underline;
  font-size: 12px;
}

.urgent {
  color: red;
}
</style>
